import React, { useEffect, useState } from 'react';
import { TemplateCard } from 'components/PointSolutions/TemplateCard/TemplateCard';
import './SubcategoryColumn.scss';

interface SubcategoryColumnProps {
  subcategory: string;
  subcatKey: string;
  iconURL: string;
  isActivated: boolean;
  bgColor: string;
  templates?: any[]; // Update this type later when hooking up
  index: number;
  isLastColumn?: boolean;
  className?: string;
  location?: string;
  onClick: (template: any) => void;
}

export const SubcategoryColumn = (props: SubcategoryColumnProps) => {
  const {
    subcategory,
    templates,
    iconURL,
    bgColor,
    isActivated,
    index,
    subcatKey,
    isLastColumn,
    location,
    className,
    onClick,
  } = props;
  const [templatesToRender, setTemplatesToRender] = useState<any>([]);
  const MAIN_CLASS = 'subcategory-column';

  useEffect(() => {
    setTemplatesToRender(templates?.filter((templateData: any) => templateData?.subcategory?.key === subcatKey));
    //eslint-disable-next-line
  }, [templates]);

  return (
    <div className={`${MAIN_CLASS}${className ? ` ${className}` : ''}`}>
      <div className="sub-categorytile">
        <div className={"sub-categorytile-inner " + (isActivated?'activated':'')}>
          <div className="meta">
            <img src="https://flybits.app/resources/Visa_Brandmark_White_RGB_2021-200.png" className="sub-icon"/>
            <div className="sub-title">{subcategory}</div>
          </div>
          {isActivated?(
            <img src="https://flybits.app/resources/check.png" className="activation-status"></img>
          ):(
            <img src="https://flybits.app/resources/add.png" className="activation-status"></img>
          )}
        </div>
      </div>
      <div className="templates-wrapper">
        {templatesToRender && templatesToRender.length > 0 ? (
          templatesToRender.map((template: any) => (
            <TemplateCard
              className={`${MAIN_CLASS}__template-card`}
              headerImage={template.icon}
              key={template.id}
              id={template.id}
              name={template.name}
              onClick={() => onClick(template)}
            />
          ))
        ) : (
          <TemplateCard className={`${MAIN_CLASS}__template-card`}  onClick={() => {}} />
        )}
      </div>
    </div>
  );
};
